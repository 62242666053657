<style>
.page-title-card {
  display: flex;
  width: 100%;
}

.page-title-card-body {
  padding: 0.7rem;
  background: #2a3042;
  border-top: 1px solid #222736;
  border-left: 1px solid #222736;
  flex-grow: 1;
  box-shadow: 0 1px 1px rgba(0,0,0,.75);
}

.profile-container {
  display: grid;
  grid-area: content;
  grid-template-columns: 5fr auto;
  grid-column-gap: 20px;
  margin-left: 5rem;
  margin-right: 7rem;
}
@media (max-width: 768px) {
  .profile-container {
    grid-column-gap: 15px;
    margin-left: unset;
    margin-right: unset;
  }
}
.profile-container-left,
.profile-container-right {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 20px;
}
.profile-container-left {
  justify-content: start;
}
.profile-container-right {
  justify-content: end;
}
.profile-container-item {
//display: grid;
  align-items: center;
  grid-gap: 6px;
//grid-auto-flow: column;
  cursor: default;
}
@media (min-width: 1025px) {
  .profile-container-mobile-alt {
    display: none !important;
  }
  .mobile-playing-status {
    display: none !important;
  }
  .profile-mobile {
    display: none !important;
  }
  .page-title-card-body.gradient-border {
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
  }
}
@media (max-width: 1024px) {
  .page-title-card-body.gradient-border {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    margin-bottom: 3px;
  }
  .profile-desktop {
    display: none !important;
  }

  .profile-container-hide-sm {
    display: none !important;
  }

  .profile-container-data {
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mobile-playing-status {
    padding: 0.2rem;
    width: 100%;
    background-color: var(--gray);
    box-shadow: 0 1px 1px var(--gray);
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mp_status_offline {
    background-color: var(--danger);
    box-shadow: 0 1px 1px var(--danger);
  }

  .mp_status_online {
    background-color: var(--primary);
    box-shadow: 0 1px 1px var(--primary);
  }
}
.c-watch-container {
  margin: 0.2rem;
}
.btn-watch-active {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #4285F4;
  }

  100% {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
  }
}
.hard-border {
  border-radius: 0 !important;
}
.pulse-warning {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-warning 2s infinite;
}
@keyframes pulse-warning {
  0% {
    box-shadow: 0 0 0 0 #f1b44c;
  }

  100% {
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }
}
.pulse-danger {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-danger 2s infinite;
}
@keyframes pulse-danger {
  0% {
    box-shadow: 0 0 0 0 #f46a6a;
  }

  100% {
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }
}
</style>
<script>
import { VclCode } from 'vue-content-loading';

import { HalfCircleSpinner, CirclesToRhombusesSpinner } from 'epic-spinners'
import {AccountStatus, Game} from '@/enums';
import CopyableText from "@/components/CopyableText";

import * as party from 'party-js/party.min.js';
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id'],
  components: {
    ProfileCore: () => import('@/components/lazy/profile/ProfileCore'),
    CopyableText,
    // eslint-disable-next-line vue/no-unused-components
    VclCode,
    HalfCircleSpinner,
    CirclesToRhombusesSpinner
  },
  computed: {
    ...mapGetters(['getDTLocale','getUILanguage','getSubscriberStatus','getServers','getMemberships']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async deleteWatchProfile() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: this.cftools_id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/watchers/profile`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.entitlements.watchProfileActive = false;
          this.$toast.success(this.$t('watchers.delete.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    async createWatchProfile() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: this.cftools_id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/watchers/profile`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.entitlements.watchProfileActive = true;
          this.$toast.success(this.$t('watchers.create.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else if(response.status === 402) {
            this.$refs.watchersUpsell.show();
          } else if(response.status === 409) {
            this.$toast.error(this.$t('watchers.create.error.restricted'));
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    getPlayState() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/playState`, {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.playStateAvailable = true;
            this.playState = data.playState;
          })
          .catch(error => {
            this.playStateError = true;
          });
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/status`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.account = data.account;
          this.profile = data.profile;
          this.entitlements = data.entitlements;
          this.getPlayState();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    overridePersona(personaName, avatar, error = false) {
      if(this.profile.display_name && this.profile.avatar) return;
      this.profile.error = error;
      this.profile.steamOverride = true;
      if(!error) {
        this.profile.display_name = personaName;
        this.profile.avatar = avatar;
      }
    },
    entitlementsEffectConfetti(event, colorSchema) {
      event.preventDefault();
      let colors = ['#FFA68D', '#FD3A84']; // Default
      if(colorSchema === 0) { // Staff
        colors = ['#F8234D', '#F47FFF'];
      } else if(colorSchema === 1) { // Founders edition (gold)
        colors = ['#FFD700', '#E2B007'];
      } else if(colorSchema === 2) {
        colors = ['#6667AB', '#FFFFFF']; // Partners 2022
      } else if(colorSchema === 3) {
				colors = ['#2e3134', '#FFFFFF']; // Enterprise Partners
			} else if(colorSchema === 4) {
				colors = ['#b44659', '#FFFFFF']; // Partners 2023
			} else if(colorSchema === 5) {
	      colors = ['#E5E4E2', '#1a1b1d']; // Advanced Plan
      }
      party.element(event.target, {
        color: colors,
        count: party.variation(25, 0.5),
        size: party.minmax(6, 10),
        velocity: party.minmax(-300, -600),
        angularVelocity: party.minmax(6, 9)
      });
    }
  },
  mounted() {
    this.getData();

    // PlayState API is not WS compatible/aware as it doesnt fit with the channel system
    this.playStateTimer = setInterval(() => {
      this.getPlayState();
    }, 10*1000);
  },
  beforeDestroy() {
    clearInterval(this.playStateTimer);
  },
  data() {
    return {
      Game: Game,
      AccountStatus: AccountStatus,
      ready: false,
      error: false,
      account: null,
      entitlements: null,
      profile: {
        error: false,
        steamOverride: false
      },
      playStateAvailable: false,
      playStateError: false,
      playState: null,
      playStateTimer: null
    }
  }
};
</script>

<template>
  <div class="loose-page-content">
    <template v-if="ready">
      <template v-if="account.is_bot">
        <div>
          Profile Unavailable<br>
          This is an official CFTools bot account. Management features are unavailable.
        </div>
        <div class="row">
          <div class="col-lg justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fas fa-ban info-component-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--
      <template v-else-if="account.status === AccountStatus.BANNED">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-sm-12">
            <div class="card text-white-50 bg-danger">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.banned.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.banned.message') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fas fa-ban info-component-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="account.status === AccountStatus.TERMINATED">
        <div class="row justify-content-center align-middle">
          <div class="col-lg-12 col-sm-12">
            <div class="card bg-warning border-warning">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.terminated.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.terminated.message') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fas fa-ban info-component-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      -->
      <template v-else>
        <!--
        <template v-if="account.status === AccountStatus.BANNED">
          <div class="w-100">
            <div class="card hard-border pulse-warning bg-warning border-warning mb-0 pb-0">
              <div class="card-body p-2 m-0">
                <h5 class="mt-0 mb-1 text-black">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.banned.title') }}
                </h5>
                <p class="card-text text-black">
                  {{ $t('profile.errors.banned.message') }}
                </p>
              </div>
            </div>
          </div>
        </template>
        -->
        <template v-if="account.status === AccountStatus.TERMINATED">
          <div class="w-100">
            <div class="card hard-border pulse-danger bg-danger border-danger mb-0 pb-0">
              <div class="card-body p-2 m-0">
                <h5 class="mt-0 mb-1 text-black">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.terminated.title') }}
                </h5>
                <p class="card-text text-black">
                  {{ $t('profile.errors.terminated.message') }}
                </p>
              </div>
            </div>
          </div>
        </template>
	      <!--
        <template v-if="entitlements.staffIdentifier">
          <div class="w-100">
            <div class="card hard-border bg-light border-warning mb-0 pb-0">
              <div class="card-body p-2 m-0">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fad fa-clipboard-user mr-1"></i>
                  {{ $t('profile.errors.staff.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.staff.message') }}
                </p>
              </div>
            </div>
          </div>
        </template>
        -->
        <!-- Mobile exclusive playing status bar -->
        <template v-if="entitlements.extendedPrivacy && (!playStateAvailable || playStateAvailable && !playState.online)">
          <div class="mobile-playing-status bg-warning" style="height: 24px;">
            <div class="text-black">
              <div class="text-center">
                <b>
                  <i class="fad fa-user-secret"></i> {{ $t('profile.privacy') }}
                </b>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="playStateAvailable" class="mobile-playing-status" :class="{mp_status_online: playState.online, mp_status_offline: !playState.online}">
            <div class="text-white">
              <div class="text-center">
                <template v-if="playState.online">
	                <template v-if="playState.server.id">
	                  <img :src="'/' +gameIcon(playState.server.game)" height="16" width="16" class="align-middle">
	                  <span>
	                    {{ playState.server.name | truncate(30) }}
	                    <small>
	                      ({{ playState.server.status.players }}/{{ playState.server.status.slots }})
	                    </small>
	                  </span>
	                </template>
	                <template v-else>
		                <template v-if="playState.server.game">
			                <img :src="'/' +gameIcon(playState.server.game)" height="16" width="16" class="align-middle">
		                </template>
		                <template v-else>
			                <i class="fad fa-gamepad-alt"/>
		                </template>
		                <template v-if="playState.server.name">
			                {{ playState.server.name | truncate(42) }} ({{ playState.server.status.players }}/{{ playState.server.status.slots }})
		                </template>
		                <template v-else>
			                <h6 class="m-0 p-0 text-uppercase ml-1 d-inline-block">{{ $t('profile.playstate.online') }}</h6>
		                </template>
	                </template>
                </template>
                <span v-else>
                  <i>{{ $t('profile.playstate.offline') }}</i>
                </span>
              </div>
            </div>
          </div>
          <div v-else-if="playStateError" class="mobile-playing-status" style="height: 24px;">
            <div class="text-danger">
              <div class="text-center">
                <b>
                  <i class="far fa-exclamation-triangle"></i> playStateAPI error
                </b>
              </div>
            </div>
          </div>
          <div v-else class="mobile-playing-status" style="height: 24px;">
            <div class="text-white">
              <div class="text-center">
                <half-circle-spinner :animation-duration="900" :size="18" class="align-middle d-inline-block"/>
              </div>
            </div>
          </div>
        </template>

        <div class="page-title-card">
          <div class="page-title-card-body"  :class="
            {
							'gradient-border': entitlements.foundersBorder,
							'gradient-border gradient-border-p2022': entitlements.p2022Border,
							'gradient-border gradient-border-p2023': entitlements.p2023Border,
							'gradient-border gradient-border-enterprise': entitlements.enterpriseBorder,
							'gradient-border gradient-border-advanced': entitlements.advancedBorder
						}"
          >
            <div class="profile-container">
              <!-- Core info -->
              <div class="profile-container-left">
                <template v-if="profile.error === true">
                  <i class="fal fa-exclamation-triangle text-danger"></i>
                </template>
                <template v-else-if="profile.avatar !== null && profile.display_name !== null">
                  <div class="profile-container-item">
                    <img :src="profile.avatar" alt="" class="avatar-xs rounded-circle ">
                  </div>
                  <div class="profile-container-item profile-container-data align-middle" id="steamOverride">
                    <span class="h3 pb-0 mb-0">
                      {{ profile.display_name }}
                      <i v-if="profile.steamOverride" class="fab fa-steam"></i>
                    </span>
                  </div>
                  <b-tooltip target="steamOverride" v-if="profile.steamOverride">
                    {{ $t('profile.steam.override') }}
                  </b-tooltip>
                </template>
                <template v-else>
                  <div class="profile-container-item">
                    <half-circle-spinner :animation-duration="900" :size="32" class="align-middle d-inline-block"/>
                  </div>
                  <div class="profile-container-item profile-container-data align-middle">
                    <span class="h3 pb-0 mb-0">
                      <circles-to-rhombuses-spinner
                          :animation-duration="900"
                          :circles-num="3"
                          :circle-size="9"
                      />
                    </span>
                  </div>
                </template>

                <!-- Mobile hidden -->
                <div class="profile-container-item profile-container-data profile-container-hide-sm">
                  <span class="h6 pb-0 mb-0">
                    CFTools ID
                  </span>
                  <CopyableText class="text-code" :text="cftools_id"/>
                </div>
                <div
                    class="profile-container-item profile-container-data profile-container-hide-sm"
                    v-if="entitlements.staffIdentifier"
                    v-on:click="entitlementsEffectConfetti($event, 0)"
                >
                    <button class="btn btn-light btn-sm" id="staffIdentifier">
                      <img src="@/assets/images/logo-light.svg" alt height="18" />
                    </button>
                    <b-tooltip target="staffIdentifier" >
                      CFTools Cloud Staff
                    </b-tooltip>
                </div>
                <div
                    class="profile-container-item profile-container-data profile-container-hide-sm"
                    v-if="entitlements.foundersBadge"
                    v-on:click="entitlementsEffectConfetti($event, 1)"
                >
                  <button class="btn btn-light btn-sm" id="foundersBadge" style="background: #e2b007;">
                    <i class="fad fa-stars font-size-16"></i>
                  </button>
                  <b-tooltip target="foundersBadge">
                    Limited Founders Edition badge
                  </b-tooltip>
                </div>
                <div
                    class="profile-container-item profile-container-data profile-container-hide-sm"
                    v-if="entitlements.p2022Badge"
                    v-on:click="entitlementsEffectConfetti($event, 2)"
                >
                  <button class="btn btn-light btn-sm" id="p2022Badge" style="background: #6869AC;">
                    <i class="fad fa-globe-stand font-size-16"></i>
                  </button>
                  <b-tooltip target="p2022Badge">
                    Partners Badge, 2022 Edition
                  </b-tooltip>
                </div>
	              <div
			              class="profile-container-item profile-container-data profile-container-hide-sm"
			              v-if="entitlements.p2023Badge"
			              v-on:click="entitlementsEffectConfetti($event, 4)"
	              >
		              <button class="btn btn-light btn-sm" id="p2023Badge" style="background: #b44659;">
			              <i class="fad fa-globe-stand font-size-16"></i>
		              </button>
		              <b-tooltip target="p2023Badge">
			              Partners Badge, 2023 Edition
		              </b-tooltip>
	              </div>
	              <div
			              class="profile-container-item profile-container-data profile-container-hide-sm"
			              v-if="entitlements.enterpriseBadge"
			              v-on:click="entitlementsEffectConfetti($event, 3)"
	              >
		              <button class="btn btn-light btn-sm" id="enterpriseBadge" style="background: #2e3134;">
			              <i class="fad fa-globe font-size-16" style="--fa-primary-color: #2e3134; --fa-secondary-color: white;"></i>
		              </button>
		              <b-tooltip target="enterpriseBadge">
			              Enterprise partner
		              </b-tooltip>
	              </div>
	              <div
			              class="profile-container-item profile-container-data profile-container-hide-sm"
			              v-if="entitlements.advancedBadge"
			              v-on:click="entitlementsEffectConfetti($event, 5)"
	              >
		              <button class="btn btn-light btn-sm" id="advancedBadge" style="background: #E5E4E2;">
			              <i class="fad fa-hand-receiving font-size-14" style="--fa-primary-color: #1a1b1d; --fa-secondary-color: #1a1b1d;"></i>
		              </button>
		              <b-tooltip target="advancedBadge">
			              Advanced Subscriber
		              </b-tooltip>
	              </div>
              </div>
              <!-- Right side (playing status) -->
              <div class="profile-container-right">
                <div class="profile-container-item profile-container-data profile-container-hide-sm" v-if="!profile.isMyself">
                  <div class="c-watch-container">
                    <button class="btn btn-sm btn-primary btn-watch-active" v-if="entitlements.watchProfileActive" v-on:click="deleteWatchProfile()">
                      <i class="fad fa-eye" />
                    </button>
                    <button class="btn btn-sm btn-light" v-else v-on:click="createWatchProfile()">
                      <i class="fad fa-eye" />
                    </button>
                  </div>
                </div>

                <div class="profile-container-item profile-container-data profile-container-hide-sm">
                  <!--
                  Loading button version
                  <button class="btn btn-light">
                    <half-circle-spinner :animation-duration="900" :size="24" class="align-middle d-inline-block"/>
                  </button>
                  -->
                  <template v-if="entitlements.extendedPrivacy && (!playStateAvailable || playStateAvailable && !playState.online)">
                    <button class="btn btn-warning" disabled="disabled">
                      <span class="text-black">
                        <i class="fad fa-user-secret"/> {{ $t('profile.privacy') }}
                      </span>
                    </button>
                  </template>
                  <template v-else>
                    <template v-if="playStateAvailable">
                      <template v-if="playState.online">
                        <template v-if="playState.server.id">
                          <router-link :to="'/dashboard/'+playState.server.id">
                            <button class="btn btn-primary btn-rounded">
                              <img :src="'/' +gameIcon(playState.server.game)" height="16" width="16" class="align-middle">
                              {{ playState.server.name | truncate(42) }} ({{ playState.server.status.players }}/{{ playState.server.status.slots }})
                            </button>
                          </router-link>
                        </template>
                        <template v-else>
                          <button class="btn btn-primary btn-rounded" id="playStateBtn">
	                          <template v-if="playState.server.game">
		                          <img :src="'/' +gameIcon(playState.server.game)" height="16" width="16" class="align-middle" v-if="playState.server.game">
		                        </template>
			                      <template v-else>
				                      <i class="fad fa-gamepad-alt"/>
			                      </template>
                            <template v-if="playState.server.name">
                              {{ playState.server.name | truncate(42) }} ({{ playState.server.status.players }}/{{ playState.server.status.slots }})
                            </template>
                            <template v-else>
                              <h6 class="m-0 p-0 text-uppercase ml-1 d-inline-block">{{ $t('profile.playstate.online') }}</h6>
                            </template>
                          </button>
                          <b-tooltip target="playStateBtn">
                            {{ $t('profile.playstate.unauthorized2') }}
                          </b-tooltip>
                        </template>
                      </template>
                      <template v-else>
                        <button class="btn btn-danger btn-rounded" disabled="disabled">
                          <i class="fal fa-power-off"/> {{ $t('profile.playstate.offline') }}
                        </button>
                      </template>
                    </template>
                    <template v-else-if="playStateError">
                      <button class="btn btn-danger" disabled="disabled"  id="playStateErrorBtn">
                        <i class="far fa-exclamation-triangle font-size-18"></i>
                      </button>
                      <b-tooltip variant="danger" target="playStateErrorBtn">playStateAPI error</b-tooltip>
                    </template>
                    <template v-else>
                      <button class="btn btn-light">
                        <half-circle-spinner :animation-duration="900" :size="24" class="align-middle d-inline-block"/>
                      </button>
                    </template>
                  </template>
                </div>
                <div class="profile-container-item profile-container-data d-lg-none">
                  <div class="c-watch-container" v-if="!profile.isMyself">
                    <button class="btn btn-sm btn-primary btn-watch-active" v-if="entitlements.watchProfileActive" v-on:click="deleteWatchProfile()">
                      <i class="fad fa-eye" />
                    </button>
                    <button class="btn btn-sm btn-light" v-else v-on:click="createWatchProfile()">
                      <i class="fad fa-eye" />
                    </button>
                  </div>
                </div>
              </div>
              <!-- Infoset for mobile -->
              <div class="profile-container-mobile-alt">
                <div class="mobile-profile-container-item">
                  <span class="h6 pb-0 mb-0">
                    CFTools ID
                  </span>
                  <CopyableText class="text-code" :text="cftools_id"/>
                </div>
                <div class="mobile-profile-container-item">

                </div>
              </div>
            </div>
          </div>
        </div>
        <ProfileCore @overridePersona="overridePersona" :cftools_id="cftools_id" :privacyMode="entitlements.extendedPrivacy"/>
      </template>
    </template>
    <template v-else-if="error">
      <div class="col-lg-12 col-sm-12 row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </div>
      </div>
    </template>
    <b-modal
        id="watchersUpsell"
        ref="watchersUpsell"
        size="lg"
        hide-header
        hide-footer
        content-class="hard-border c-news-modal"
        :scrollable="true"
    >
      <div>
        <div class="news-header">
          <h4 class="d-inline-block">
            {{ $t('watchers.subscription.message') }}
          </h4>
          <button class="btn btn-sm btn-outline-primary float-right d-inline-block" @click="$bvModal.hide('watchersUpsell')">
            Got it
          </button>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col text-center">
            <h2 class="p-0">
              {{ $t('watchers.subscription.title') }}
            </h2>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col">
            <div class="text-center">
              <router-link :to="{name: 'billing_component', params: {component: 'subscription'}}">
                <button class="btn btn-primary btn-lg text-uppercase">
                  {{ $t('watchers.subscription.button') }}
                </button>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </b-modal>
  </div>

</template>
